import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import ContentCarousel from '../components/ContentCarousel';
import { getRandomContent, addToWaitlist } from '../services/firebase';
import { ContentItem, DraftLotteryVideo, WaitlistFormData } from '../types';
import {Widget} from '@typeform/embed-react'; 
import Navbar from '../components/Navbar';

const HomePage: React.FC = () => {
  const [carouselItems, setCarouselItems] = useState<ContentItem[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getRandomContent(20);
        setCarouselItems(content);
        
        const tags = Array.from(new Set(content.flatMap(item => item.tags)));
        setAvailableTags(tags);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };
    fetchContent();
  }, []);

  const handleItemClick = (item: ContentItem | DraftLotteryVideo) => {
    if ('type' in item) {
      navigate(`/punishments/${item.id}`);
    } else {
      navigate(`/draft-lottery/${item.id}`);
    }
  };

  const filteredItems = selectedTags.length > 0
    ? carouselItems.filter(item => item.tags.some(tag => selectedTags.includes(tag)))
    : carouselItems;

  return (
   
    <div className="min-h-screen flex flex-col bg-white text-black ">
     <Navbar />
      <header className="w-full" > 
        <div className="relative w-full h-0 pb-[56.25%]">
          <img 
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={require("../assets/FL_Cover_2.jpg")}
            alt="Fantasy Loser Cover"
          />
        </div>
      </header>
      <main className="flex-grow container mx-auto px-4 flex flex-col items-center">
        <section className="mb-12 w-full ">
          <h2 className="text-5xl font-semibold mb-6 text-black text-left font-eau italic mt-6">TOP LOSER PUNISHMENTS</h2>
          {filteredItems.length > 0 ? (
            <ContentCarousel 
              items={filteredItems} 
              onItemClick={handleItemClick}
              cardsToShow={3}
            />
          ) : (
            <p className="text-center text-gray-400">Content Loading</p>
          )}
        </section>
        
        <br />
        <h1 className="text-left w-[75%] text-2xl text-bold font-source">Dear Commissioners, </h1>
        <br />
        <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-source">Any fantasy league commissioner knows that their league would be lost without them. We're looking for the best of the best fantasy football league commissioners to make the cut for our 53 man roster. Is that you?</h2>
        <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-source">You'll get early access before the public to our full suite of tools and features like a commissioner hub, loser punishment ideas and a league locker, and you'll join our panel of 53 commissioner experts where we'll look to you for critical feedback and insights on new products, features and ideas that only our top 53 can provide.</h2>
        <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-source">Join the Fantasy Loser depth chart by signing up for our wait list and, if you make final cuts, we'll hit the gridiron together! This is the job application you actually WANT to fill in.</h2>
        <h2 className="text-2xl w-[75%] mb-4 text-[#f8f8fff] font-cedar "> - The FL Team</h2>
        <br />
        
        <section className="mb-12 w-full max-w-4xl lg:max-w-5xl bg-[#444444] p-6 rounded-lg shadow-lg h-[40rem]">
          <Widget id="aUOuhTAX" className="w-full h-full"/>
        </section>
    
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;