import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  Auth,
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { Firestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../services/firebase';
import { useYahooAuth } from './YahooAuthContext';

interface AuthContextType {
  user: User | null;
  userRole: string | null;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, role: string) => Promise<void>;
  logout: () => Promise<void>;
  getDashboardRoute: () => string;
  isCommissioner: (leagueId: string) => Promise<boolean>;
  yahooIsAuthenticated: boolean;
  yahooLogin: (leagueId: string) => void;
  yahooLogout: (leagueId: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { yahooIsAuthenticated, yahooLogin, yahooLogout } = useYahooAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const role = userDoc.data()?.role || null;
          setUserRole(role);
          console.log('User role set to:', role); // Add this line for debugging
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      } else {
        setUserRole(null);
      }
      setIsLoading(false);
    });
  
    return unsubscribe;
  }, []);

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const role = userDoc.data()?.role || null;
        setUserRole(role);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const signup = async (email: string, password: string, role: string) => {
    setIsLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      if (user) {
        await setDoc(doc(db, 'users', user.uid), { role });
        setUserRole(role);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await signOut(auth);
      setUserRole(null);
    } finally {
      setIsLoading(false);
    }
  };

  const getDashboardRoute = () => {
    if (userRole === 'admin') {
      return '/admin';
    } else if (userRole === 'commissioner') {
      return '/authHome';
    } else {
      return '/';
    }
  };

  const isCommissioner = async (leagueId: string): Promise<boolean> => {
    if (!user) return false;
    const leagueDoc = await getDoc(doc(db, 'leagues', leagueId));
    const leagueData = leagueDoc.data();
    return leagueData?.commissioners?.includes(user.uid) || false;
  };

  const value = {
    user,
    userRole,
    isLoading,
    login,
    signup,
    logout,
    getDashboardRoute,
    isCommissioner,
    yahooIsAuthenticated,
    yahooLogin,
    yahooLogout
  };



  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};