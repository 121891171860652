// src/pages/AuthenticatedHome.tsx

import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContentCarousel from '../components/ContentCarousel';
import Modal from '../components/Modal';
import { getContentByTag, getDraftLotteryVideos } from '../services/firebase';
import { ContentItem, DraftLotteryVideo } from '../types';
import { Link } from 'react-router-dom';

const AuthenticatedHome: React.FC = () => {
  const [punishments, setPunishments] = useState<ContentItem[]>([]);
  const [draftLotteryVideos, setDraftLotteryVideos] = useState<DraftLotteryVideo[]>([]);
  const [selectedItem, setSelectedItem] = useState<ContentItem | DraftLotteryVideo | null>(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const fetchedPunishments = await getContentByTag('punishment');
        setPunishments(fetchedPunishments);

        const fetchedVideos = await getDraftLotteryVideos();
        setDraftLotteryVideos(fetchedVideos);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };
    fetchContent();
  }, []);

  const handleItemClick = (item: ContentItem | DraftLotteryVideo) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  return (
    <div className="min-h-screen flex flex-col bg-[#333333] text-white">
        <header className="relative h-64 sm:h-96 overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: 'url("/path-to-your-image.jpg")' }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="relative h-full flex items-center justify-center">
          <h1 className="text-5xl sm:text-7xl font-bold text-[#18f7b1] z-30">
            WHAT A LOSER!
          </h1>
        </div>
      </header>
        <main className="flex-grow container mx-auto px-4 py-8 flex flex-col items-center">
          <div className="text-center mb-12 w-full max-w-4xl lg:max-w-5xl">
            <h1 className="text-5xl font-bold mb-4 text-[#18f7b1]">Welcome to FantasyLoser</h1>
            <p className="text-xl">Manage your fantasy leagues like never before</p>
          </div>

          <section className="mb-12 w-full max-w-4xl lg:max-w-5xl">
            <h2 className="text-3xl font-bold mb-2 text-[#18f7b1] text-center">FEATURED LOSER</h2>
            
            <div className="mb-12 w-full max-w-4xl lg:max-w-5xl rounded-lg overflow-hidden shadow-lg">
            <div className="relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://www.youtube.com/embed/J-CbRJtIo00"
                title="Fantasy Football Hype Video"
                className="absolute top-0 left-0 w-full h-full"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <p className="text-lg mb-4 text-center"><b>Meet Rob,</b> this week's unfortunate fantasy failure!</p>
            <div className="bg-[#444444] rounded-lg p-6 shadow-lg">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="mb-2"><span className="font-bold text-[#18f7b1]">League:</span> Super Fantasy League</p>
                  <p className="mb-2"><span className="font-bold text-[#18f7b1]">Platform:</span> ESPN</p>
                  <p className="mb-2"><span className="font-bold text-[#18f7b1]"># of teams:</span> 12</p>
                  <p className="mb-2"><span className="font-bold text-[#18f7b1]">Record:</span> 2-11</p>
                  <p className="mb-2"><span className="font-bold text-[#18f7b1]">Pts Scored:</span> 1,234</p>
                </div>
                <div>
                  <p className="mb-2"><span className="font-bold text-[#18f7b1]">League Punishment:</span></p>
                  <p>The loser must perform a stand-up comedy routine at a local open mic night while wearing a jersey of their most-benched player.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-12 w-full max-w-5xl lg:max-w-6xl">
          <h2 className="text-2xl font-semibold mb-4 text-[#18f7b1] text-center">Punishments</h2>
          {punishments.length > 0 ? (
            <ContentCarousel 
              items={punishments} 
              onItemClick={handleItemClick}
            />
          ) : (
            <p className="text-center text-gray-400">No punishments available</p>
          )}
          <div className="flex justify-center mt-4">
            <Link to="/dashboard" className="w-48 p-2 bg-[#18f7b1] text-[#333333] font-semibold rounded hover:bg-[#0fd494] transition duration-300 text-center">
              Explore
            </Link>
          </div>
        </section>

        <section className="mb-12 w-full max-w-5xl lg:max-w-6xl">
          <h2 className="text-2xl font-semibold mb-4 text-[#18f7b1] text-center">Draft Lottery Videos</h2>
          {draftLotteryVideos.length > 0 ? (
            <ContentCarousel 
              items={draftLotteryVideos} 
              onItemClick={handleItemClick}
            />
          ) : (
            <p className="text-center text-gray-400">No draft lottery videos available</p>
          )}
          <div className="flex justify-center mt-4">
            <Link to="/dashboard" className="w-48 p-2 bg-[#18f7b1] text-[#333333] font-semibold rounded hover:bg-[#0fd494] transition duration-300 text-center">
              Explore
            </Link>
          </div>
        </section>
      </main>
      <Footer />
      <Modal item={selectedItem} onClose={handleCloseModal} />
    </div>
  );
};

export default AuthenticatedHome;