import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContentByType } from '../services/firebase';
import { ContentItem } from '../types';
import Footer from '../components/Footer';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Navbar from '../components/Navbar';

// Updated Slider component
const Slider: React.FC<{
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
}> = ({ value, onChange, min, max, step }) => {
  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <div className="relative w-full h-6 flex items-center px-3">
      <div className="absolute left-0 right-0 h-2 bg-gray-200 rounded-full">
        <div
          className="absolute h-full bg-[#18f7b1] rounded-full"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="absolute left-0 right-0 w-full h-6 opacity-0 cursor-pointer z-10"
      />
      <div
        className="absolute w-6 h-6 bg-white border-2 border-[#18f7b1] rounded-full shadow pointer-events-none"
        style={{ left: `calc(${percentage}% - 6px)` }}
      />
    </div>
  );
};

const ExplorePage: React.FC = () => {
  const [punishments, setPunishments] = useState<ContentItem[]>([]);
  const [filteredPunishments, setFilteredPunishments] = useState<ContentItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [embarrassmentLevel, setEmbarrassmentLevel] = useState(0);
  const [riskLevel, setRiskLevel] = useState(0);
  const [longTermEffects, setLongTermEffects] = useState(0);
  const [selectedFLRating, setSelectedFLRating] = useState<string>('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPunishments = async () => {
      try {
        const content = await getContentByType('punishment');
        setPunishments(content);
        setFilteredPunishments(content);
        
        const tags = Array.from(new Set(content.flatMap(item => item.tags || [])));
        setAvailableTags(tags);
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching punishments:', error);
        setIsLoading(false);
      }
    };

    fetchPunishments();
  }, []);

  useEffect(() => {
    const filtered = punishments.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedTags.length === 0 || selectedTags.every(tag => item.tags?.includes(tag))) &&
      (embarrassmentLevel === 0 || parseInt(item.embarrassmentLevel) >= embarrassmentLevel) &&
      (riskLevel === 0 || parseInt(item.riskLevel) >= riskLevel) &&
      (longTermEffects === 0 || parseInt(item.longTermEffects) >= longTermEffects) &&
      (selectedFLRating === '' || item.flRating === selectedFLRating)
    );
    setFilteredPunishments(filtered);
  }, [searchTerm, selectedTags, embarrassmentLevel, riskLevel, longTermEffects, selectedFLRating, punishments]);

  const handleCardClick = (item: ContentItem) => {
    navigate(`/punishments/${item.id}`);
  };

  const handleTagToggle = (tag: string) => {
    setSelectedTags(prev =>
      prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
    );
  };

  if (isLoading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col bg-white text-black">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-5xl font-eau font-bold mb-8 text-center">Explore Punishments</h1>
        
        {/* Search Bar */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search punishments..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>

        {/* Filter Tags */}
        <div className="mb-6 flex flex-wrap gap-2">
          {availableTags.map(tag => (
            <button
              key={tag}
              onClick={() => handleTagToggle(tag)}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedTags.includes(tag)
                  ? 'bg-[#18f7b1] text-black'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              {tag}
            </button>
          ))}
        </div>

        {/* Advanced Filters with smooth transition */}
        <div className="mb-6">
          <button
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            className="flex items-center justify-between w-full p-2 bg-gray-100 rounded-lg transition-colors duration-300 hover:bg-gray-200"
          >
            <span>Advanced Filters</span>
            {showAdvancedFilters ? <ChevronUp /> : <ChevronDown />}
          </button>
          
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${showAdvancedFilters ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="px-3">
                <label className="block text-sm font-medium text-gray-700 mb-1">Embarrassment Level (Min)</label>
                <Slider
                  value={embarrassmentLevel}
                  onChange={setEmbarrassmentLevel}
                  min={0}
                  max={5}
                  step={1}
                />
                <span className="mt-1 block text-center">{embarrassmentLevel}</span>
              </div>
              <div className="px-3">
                <label className="block text-sm font-medium text-gray-700 mb-1">Risk Level (Min)</label>
                <Slider
                  value={riskLevel}
                  onChange={setRiskLevel}
                  min={0}
                  max={5}
                  step={1}
                />
                <span className="mt-1 block text-center">{riskLevel}</span>
              </div>
              <div className="px-3">
                <label className="block text-sm font-medium text-gray-700 mb-1">Long Term Effects (Min)</label>
                <Slider
                  value={longTermEffects}
                  onChange={setLongTermEffects}
                  min={0}
                  max={5}
                  step={1}
                />
                <span className="mt-1 block text-center">{longTermEffects}</span>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">FL Rating</label>
                <select
                  value={selectedFLRating}
                  onChange={(e) => setSelectedFLRating(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                >
                  <option value="">Any</option>
                  <option value="Cute & Fluffy    (Level 1)">Cute & Fluffy (Level 1)</option>
                  <option value="That's No Joke    (Level 2)">That's No Joke (Level 2)</option>
                  <option value="Absolutely Ridiculous    (Level 3)">Absolutely Ridiculous (Level 3)</option>
                  <option value="Legend Status    (Level 4)">Legend Status (Level 4)</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Punishment Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredPunishments.map((item) => (
            <div 
              key={item.id} 
              className="bg-white rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300 overflow-hidden border border-[#18f7b1]"
              onClick={() => handleCardClick(item)}
            >
              <div 
                className="h-48 bg-cover bg-center"
                style={{ backgroundImage: `url(${item.image || '/placeholder-image.jpg'})` }}
              ></div>
              <div className="p-4 bg-black bg-opacity-70">
                <h3 className="text-2xl font-semibold mb-2 text-[#18f7b1] overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {item.title}
                </h3>
                <div className="flex-grow bg-opacity-70">
                  <p className="text-base sm:text-xl text-gray-300 overflow-hidden overflow-ellipsis" style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                    {item.subtitle}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {filteredPunishments.length === 0 && (
          <p className="text-center text-gray-500 mt-8">No punishments found matching your criteria.</p>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ExplorePage;