import React, { useState } from 'react';
import { ContentItem } from '../types';
import { updateContent, uploadImage } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Share2, ThumbsUp, ThumbsDown, Clock, DollarSign, Pickaxe, MapPin, Flame } from 'lucide-react';
import customRatingIcon from '../assets/FL_Logo_Loser_Icon_Grn.png';

interface PunishmentDisplayProps {
  content: ContentItem;
  userRole: string | null;
  onVote: (voteType: 'up' | 'down') => void;
  upvotes: number;
  downvotes: number;
  userVote: 'up' | 'down' | null;
}

const PunishmentDisplay: React.FC<PunishmentDisplayProps> = ({
  content,
  userRole,
  onVote,
  upvotes,
  downvotes,
  userVote
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);
  const [newImage, setNewImage] = useState<File | null>(null);
  const { user } = useAuth();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditedContent(prev => ({ 
      ...prev, 
      [name]: name === 'type' ? value as 'punishment' | 'challenge' | 'draft' : value 
    }));
  };

  const handleRemoveArrayItem = (index: number, field: keyof ContentItem) => {
    const newArray = [...(editedContent[field] as string[])];
    newArray.splice(index, 1);
    setEditedContent(prev => ({ ...prev, [field]: newArray }));
  };

  const handleArrayInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, field: keyof ContentItem) => {
    const newArray = [...(editedContent[field] as string[])];
    newArray[index] = e.target.value;
    setEditedContent(prev => ({ ...prev, [field]: newArray }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewImage(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      let updatedContent = { ...editedContent };

      // Handle main image upload if there's a new image
      if (newImage) {
        const imageUrl = await uploadImage(newImage);
        updatedContent.image = imageUrl;
      }

      // Handle instruction image uploads
      const updatedInstructions = await Promise.all(
        updatedContent.instructions.map(async (instruction) => {
          if (instruction.image instanceof File) {
            const imageUrl = await uploadImage(instruction.image);
            return { ...instruction, image: imageUrl };
          }
          return instruction;
        })
      );

      updatedContent.instructions = updatedInstructions;

      // Update the content in Firebase
      await updateContent(editedContent.id, updatedContent);
      
      // Exit editing mode
      setIsEditing(false);

      // Show a success message
      alert('Content updated successfully!');

      // Force a page refresh
      window.location.reload();
    } catch (error) {
      console.error('Error updating punishment:', error);
      // Show an error message
      alert('An error occurred while updating the content. Please try again.');
    }
  };

  const CustomRating = ({ rating, maxRating = 5 }: { rating: number; maxRating?: number }) => (
    <div className="flex">
      {[...Array(maxRating)].map((_, index) => (
        <img
          key={index}
          src={customRatingIcon}
          alt={index < rating ? 'Filled rating icon' : 'Empty rating icon'}
          className={`w-10 h-10 ${index < rating ? 'opacity-100' : 'opacity-30'}`}
        />
      ))}
    </div>
  );

  const FLRating = ({ rating }: { rating: string }) => (
    <div className="text-black">
      <h3 className="flex text-5xl sm:text-6xl font-bold mb-2 italic">
        <img src={require("../assets/favicon_360.png")} className="w-16 h-16 mr-4 ml-4 rounded " alt="FL Logo" />
        RATING:
      </h3>
      <p className="text-5xl sm:text-6xl italic font-bold text-left sm:mt-20 mt-10 ml-4 mb-10">{rating}</p>
    </div>
  );

  const renderImage = (image: string | File | null, alt: string) => {
    if (typeof image === 'string' && image) {
      return (
        <img src={image} alt={alt} className="w-full h-full object-contain" />
      );
    } else if (image instanceof File) {
      return (
        <img src={URL.createObjectURL(image)} alt={alt} className="w-full h-full object-contain" />
      );
    } else {
      return <span className="text-gray-600">No image available</span>;
    }
  };

  const renderContent = () => (
    <div className="space-y-4 sm:space-y-8">
      <section>
        <h1 className="text-5xl font-bold mb-2 font-eau">{content.title}</h1>
        <p className="mb-4 text-lg sm:text-2xl">{content.subtitle}</p>
        {/* <div className="flex items-center mb-4">
          <Share2 className="mr-2" />
          <span className="mr-4">SHARE</span>
          <div className="flex items-center">
            <button
              onClick={() => onVote('up')}
              className={`mr-2 ${userVote === 'up' ? 'text-green-500' : ''}`}
              disabled={!user || (userRole !== 'commissioner' && userRole !== 'admin')}
              title={!user ? "Please sign in to vote" : (userRole !== 'commissioner' && userRole !== 'admin') ? "Only commissioners and admins can vote" : ""}
            >
              <ThumbsUp />
            </button>
            <span className="mr-2">{upvotes}</span>
            <button
              onClick={() => onVote('down')}
              className={`mr-2 ${userVote === 'down' ? 'text-red-500' : ''}`}
              disabled={!user || (userRole !== 'commissioner' && userRole !== 'admin')}
              title={!user ? "Please sign in to vote" : (userRole !== 'commissioner' && userRole !== 'admin') ? "Only commissioners and admins can vote" : ""}
            >
              <ThumbsDown />
            </button>
            <span>{downvotes}</span>
          </div>
        </div> */}
      </section>

      <section className="w-full h-48 sm:h-64 bg-gray-200 rounded-lg mb-4 flex items-center justify-center overflow-hidden">
        {renderImage(content.image, content.title)}
      </section>
      <p className="text-base sm:text-xl">{content.imageDescription}</p>

      <section className="flex flex-col sm:flex-row gap-4 mb-4">
  <div className="w-full sm:w-1/2 bg-[#18f7b1] text-black p-4 rounded-xl">
    <FLRating rating={content.flRating || '0'} />
  </div>
  
  <div className="w-full sm:w-1/2 bg-black text-white p-4 rounded-xl">
    <div className="mb-5">
      <h4 className="text-2xl font-bold mb-2">EMBARRASSMENT LEVEL:</h4>
      <CustomRating rating={parseInt(content.embarrassmentLevel) || 0} />
    </div>
    <div className="mb-5">
      <h4 className="text-2xl font-bold mb-2">RISK OF ARREST/DIVORCE/FIRING:</h4>
      <CustomRating rating={parseInt(content.riskLevel) || 0} />
    </div>
    <div className="mb-5">
      <h4 className="text-2xl font-bold mb-2">LONG TERM EFFECTS:</h4>
      <CustomRating rating={parseInt(content.longTermEffects) || 0} />
    </div>
  </div>
</section>

      <section className="border-black border-4 rounded-xl p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-4">
            <div>
              <h3 className="text-2xl font-bold mb-2 flex"><MapPin className="mr-2 flex-shrink-0 mt-1"/> PUNISHMENT LOCATION:</h3>
              <p className="text-base sm:text-xl">{content.equipmentLocker || 'N/A'}</p>
            </div>
            <div>
              <h3 className="text-2xl font-bold mb-2 flex"> <Pickaxe className="mr-2 flex-shrink-0 mt-1" />EQUIPMENT NEEDED:</h3>
              <ul className="list-disc list-inside text-base sm:text-xl">
                {(content.equipmentNeeded || []).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex items-start">
              <Clock className="mr-2 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-2xl font-bold">TIME TO COMPLETE:</h3>
                <p className="text-base sm:text-xl">{content.timeToComplete || 'N/A'}</p>
              </div>
            </div>
            <div className="flex items-start">
              <DollarSign className="mr-2 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-2xl font-bold">COST TO COMPLETE:</h3>
                <p className="text-base sm:text-xl">{content.costToComplete || 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-y-8">
        <h3 className="text-3xl sm:text-4xl font-bold mb-4">CHALLENGE INSTRUCTIONS:</h3>
        {(content.instructions || []).map((instruction, index) => (
          <div key={index} className="space-y-4">
            <h4 className="text-2xl font-bold text-black">STEP {index + 1}</h4>
            <div className="border-l-4 border-[#18f7b1] pl-4">
              <p className="mb-2 text-base sm:text-xl">{instruction.description}</p>
              {instruction.image && renderImage(instruction.image, `Instruction ${index + 1}`)}
            </div>
          </div>
        ))}
      </section>

      <section className='border-black border-4 rounded-xl p-4 '>
        <div className='flex items-start'>
        <Flame className="mr-2 flex-shrink-0 mt-1" />
        <h3 className="text-2xl font-bold mb-4">WAYS TO SPICE IT UP: </h3>
        </div>
   
        <ul className="list-disc list-inside text-base sm:text-xl">
          {(content.waysToSpiceUp || []).map((way, index) => (
            <li key={index}>{way}</li>
          ))}
        </ul>
      </section>

      <section className="flex flex-wrap gap-2 mb-4">
        {(content.tags || []).map((tag) => (
          <span key={tag} className="px-2 py-1 bg-gray-200 text-xs sm:text-sm rounded">{tag}</span>
        ))}
      </section>

      {userRole === 'admin' && (
        <button 
          onClick={() => setIsEditing(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Edit
        </button>
      )}
    </div>
  );

  const renderEditForm = () => (
    <div className="space-y-6 bg-white p-6 rounded-lg shadow-lg">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Content Type</label>
        <select
          name="type"
          value={editedContent.type}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        >
          <option value="punishment">Punishment</option>
          <option value="challenge">Challenge</option>
          <option value="draft">Draft Content</option>
        </select>
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
        <input
          type="text"
          name="title"
          value={editedContent.title}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Subtitle</label>
        <input
          type="text"
          name="subtitle"
          value={editedContent.subtitle}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Main Image</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-1 block w-full bg-gray-50"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Image Description</label>
        <textarea
          name="imageDescription"
          value={editedContent.imageDescription}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
          rows={4}
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Rating</label>
        <select
          name="flRating"
          value={editedContent.flRating}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        >
          <option value="">Select FL Rating</option>
          <option value="Cute & Fluffy    (Level 1)">Cute & Fluffy</option>
          <option value="That's No Joke    (Level 2)">That's No Joke</option>
          <option value="Absolutely Ridiculous    (Level 3)">Absolutely Ridiculous</option>
          <option value="Legend Status    (Level 4)">Legend Status</option>
        </select>
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Embarrassment Level</label>
        <input
          type="number"
          name="embarrassmentLevel"
          value={editedContent.embarrassmentLevel}
          onChange={handleInputChange}
          min="0"
          max="5"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Risk Level</label>
        <input
          type="number"
          name="riskLevel"
          value={editedContent.riskLevel}
          onChange={handleInputChange}
          min="0"
          max="5"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Long Term Effects</label>
        <input
          type="number"
          name="longTermEffects"
          value={editedContent.longTermEffects}
          onChange={handleInputChange}
          min="0"
          max="5"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Equipment Locker</label>
        <input
          type="text"
          name="equipmentLocker"
          value={editedContent.equipmentLocker}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Equipment Needed</label>
        {editedContent.equipmentNeeded.map((item, index) => (
          <input
            key={index}
            type="text"
            value={item}
            onChange={(e) => handleArrayInputChange(e, index, 'equipmentNeeded')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50 mb-2"
            placeholder={`Equipment ${index + 1}`}
          />
        ))}
        <button
          type="button"
          onClick={() => setEditedContent(prev => ({...prev, equipmentNeeded: [...prev.equipmentNeeded, '']}))
          }
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Add Equipment
        </button>
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Time to Complete</label>
        <input
          type="text"
          name="timeToComplete"
          value={editedContent.timeToComplete}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Cost to Complete</label>
        <input
          type="text"
          name="costToComplete"
          value={editedContent.costToComplete}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Challenge Instructions</label>
        {editedContent.instructions.map((instruction, index) => (
          <div key={index} className="mt-2 mb-4 p-4 border border-gray-200 rounded-md">
            <textarea
              value={instruction.description}
              onChange={(e) => {
                const newInstructions = [...editedContent.instructions];
                newInstructions[index].description = e.target.value;
                setEditedContent(prev => ({ ...prev, instructions: newInstructions }));
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50 mb-2"
              rows={3}
              placeholder={`Instruction ${index + 1}`}
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  const newInstructions = [...editedContent.instructions];
                  newInstructions[index].image = e.target.files[0];
                  setEditedContent(prev => ({ ...prev, instructions: newInstructions }));
                }
              }}
              className="mt-1 block w-full bg-gray-50 mb-2"
            />
            <button
              type="button"
              onClick={() => {
                const newInstructions = editedContent.instructions.filter((_, i) => i !== index);
                setEditedContent(prev => ({ ...prev, instructions: newInstructions }));
              }}
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
            >
              Remove Step
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setEditedContent(prev => ({...prev, instructions: [...prev.instructions, { description: '', image: null }]}))
          }
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Add Instruction
        </button>
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Ways to Spice It Up</label>
        {editedContent.waysToSpiceUp.map((way, index) => (
          <input
            key={index}
            type="text"
            value={way}
            onChange={(e) => handleArrayInputChange(e, index, 'waysToSpiceUp')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50 mb-2"
            placeholder={`Way to Spice It Up ${index + 1}`}
          />
        ))}
        <button
          type="button"
          onClick={() => setEditedContent(prev => ({...prev, waysToSpiceUp: [...prev.waysToSpiceUp, '']}))
          }
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Add Way to Spice It Up
        </button>
      </div>
  
      <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">Tags</label>
  {editedContent.tags.map((tag, index) => (
    <div key={index} className="flex items-center mb-2">
      <input
        type="text"
        value={tag}
        onChange={(e) => handleArrayInputChange(e, index, 'tags')}
        className="flex-grow rounded-l-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        placeholder={`Tag ${index + 1}`}
      />
      <button
        type="button"
        onClick={() => handleRemoveArrayItem(index, 'tags')}
        className="bg-red-500 text-white px-3 py-2 rounded-r-md hover:bg-red-600 transition duration-200"
      >
        Remove
      </button>
    </div>
  ))}
        <button
          type="button"
          onClick={() => setEditedContent(prev => ({...prev, tags: [...prev.tags, '']}))
          }
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Add Tag
        </button>
      </div>
  
      <div className="flex space-x-2">
        <button 
          onClick={handleSave}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
        >
          Save
        </button>
        <button 
          onClick={() => setIsEditing(false)}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div>
      {isEditing && userRole === 'admin' ? renderEditForm() : renderContent()}
    </div>
  );
};

export default PunishmentDisplay;