import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { getContentById, getUserRole, voteContent, getContentVotes, getRandomContent } from '../services/firebase';
import { ContentItem, DraftLotteryVideo } from '../types';
import { useTheme } from '../contexts/ThemeContext';
import { Share2, Clock, DollarSign, ThumbsUp, ThumbsDown } from 'lucide-react';
import customRatingIcon from '../assets/FL_Logo_Loser_Icon_Grn.png';
import { useAuth } from '../contexts/AuthContext';
import PunishmentDisplay from '../components/PunishmentDisplay';
import ContentCarousel from '../components/ContentCarousel';
import Navbar from '../components/Navbar';

const ContentPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [content, setContent] = useState<ContentItem | DraftLotteryVideo | null>(null);
  const [carouselItems, setCarouselItems] = useState<ContentItem[]>([]);
  const { theme } = useTheme();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState<string | null>(null);
  const [upvotes, setUpvotes] = useState(0);
  const [downvotes, setDownvotes] = useState(0);
  const [userVote, setUserVote] = useState<'up' | 'down' | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      if (id) {
        try {
          const fetchedContent = await getContentById(id);
          if (fetchedContent) {
            setContent({ ...fetchedContent, id });
          } else {
            console.error('Content not found');
          }
        } catch (error) {
          console.error('Error fetching content:', error);
        }
      }
    };

    const fetchUserRole = async () => {
      if (user) {
        try {
          const role = await getUserRole(user.uid);
          setUserRole(role);
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      } else {
        setUserRole(null);
      }
    };

    const fetchCarouselItems = async () => {
      try {
        const items = await getRandomContent(6);
        setCarouselItems(items);
      } catch (error) {
        console.error('Error fetching carousel items:', error);
      }
    };

    fetchContent();
    fetchUserRole();
    fetchCarouselItems();
  }, [id, user]);

  useEffect(() => {
    const fetchVotes = async () => {
      if (id) {
        try {
          const votes = await getContentVotes(id);
          setUpvotes(votes.upvotes);
          setDownvotes(votes.downvotes);
          if (user) {
            setUserVote(votes.userVotes[user.uid] || null);
          } else {
            setUserVote(null);
          }
        } catch (error) {
          console.error('Error fetching votes:', error);
        }
      }
      setIsLoading(false);
    };

    fetchVotes();
  }, [id, user]);

  const handleVote = async (voteType: 'up' | 'down') => {
    if (user && id && (userRole === 'commissioner' || userRole === 'admin')) {
      try {
        const newVoteType = userVote === voteType ? null : voteType;
        const result = await voteContent(id, user.uid, newVoteType);
        setUpvotes(result.upvotes);
        setDownvotes(result.downvotes);
        setUserVote(result.userVote);
      } catch (error) {
        console.error('Error voting:', error);
      }
    }
  };

  const handleCarouselItemClick = (item: ContentItem | DraftLotteryVideo) => {
    if ('type' in item) {
      navigate(`/punishments/${item.id}`);
    } else {
      navigate(`/draft-lottery/${item.id}`);
    }
  };

  const isContentItem = (item: ContentItem | DraftLotteryVideo): item is ContentItem => {
    return 'type' in item;
  };

  const CustomRating = ({ rating, maxRating = 5 }: { rating: number; maxRating?: number }) => (
    <div className="flex">
      {[...Array(maxRating)].map((_, index) => (
        <img
          key={index}
          src={customRatingIcon}
          alt={index < rating ? 'Filled rating icon' : 'Empty rating icon'}
          className={`w-10 h-10 ${index < rating ? 'opacity-100' : 'opacity-30'}`}
        />
      ))}
    </div>
  );

  const FLRating = ({ rating }: { rating: string }) => (
    <div className="text-black">
      <h3 className="flex text-2xl sm:text-3xl font-bold mb-2">
        <img src={require("../assets/FL_Logo_FL_Icon_Blk.png")} className="w-8 h-8 mr-2" alt="FL Logo" />
        RATING:
      </h3>
      <p className="text-4xl sm:text-5xl font-eau font-bold">{rating}</p>
    </div>
  );

  const renderImage = (image: string | File | null, alt: string) => {
    if (typeof image === 'string' && image) {
      return (
        <div className="w-full h-48 sm:h-64 bg-gray-200 rounded-lg mb-4 flex items-center justify-center overflow-hidden">
          <img 
            src={image} 
            alt={alt} 
            className="w-full h-full object-contain" 
          />
        </div>
      );
    } else if (image instanceof File) {
      return (
        <div className="w-full h-48 sm:h-64 bg-gray-200 rounded-lg mb-4 flex items-center justify-center overflow-hidden">
          <img 
            src={URL.createObjectURL(image)} 
            alt={alt} 
            className="w-full h-full object-contain" 
          />
        </div>
      );
    } else {
      return (
        <div className="w-full h-48 sm:h-64 bg-gray-300 rounded-lg mb-4 flex items-center justify-center">
          <span className="text-gray-600">No image available</span>
        </div>
      );
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!content) {
    return <div>Content not found</div>;
  }

  return (
    <div className={`min-h-screen flex  flex-col ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-[#333333]'}`}>
      <Navbar />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-4 sm:py-8 max-w-4xl">
          {isContentItem(content) ? (
            content.type === 'punishment' ? (
              <PunishmentDisplay
                content={content}
                userRole={userRole}
                onVote={handleVote}
                upvotes={upvotes}    
                downvotes={downvotes}
                userVote={userVote}
              />
            ) : (
              <div className="space-y-4 sm:space-y-8">
                <section>
                  <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 font-eau italic">{content.title}</h1>
                  <p className="mb-4 text-3xl">{content.subtitle}</p>
                  <div className="flex items-center mb-4">
                    <Share2 className="mr-2" />
                    <span className="mr-4">SHARE</span>
                    <div className="flex items-center">
                      <button
                        onClick={() => handleVote('up')}
                        className={`mr-2 ${userVote === 'up' ? 'text-green-500' : ''}`}
                        disabled={!user || (userRole !== 'commissioner' && userRole !== 'admin')}
                        title={!user ? "Please sign in to vote" : (userRole !== 'commissioner' && userRole !== 'admin') ? "Only commissioners and admins can vote" : ""}
                      >
                        <ThumbsUp />
                      </button>
                      <span className="mr-2">{upvotes}</span>
                      <button
                        onClick={() => handleVote('down')}
                        className={`mr-2 ${userVote === 'down' ? 'text-red-500' : ''}`}
                        disabled={!user || (userRole !== 'commissioner' && userRole !== 'admin')}
                        title={!user ? "Please sign in to vote" : (userRole !== 'commissioner' && userRole !== 'admin') ? "Only commissioners and admins can vote" : ""}
                      >
                        <ThumbsDown />
                      </button>
                      <span>{downvotes}</span>
                    </div>
                  </div>
                </section>

                <section>
                  {renderImage(content.image, content.title)}
                  <p className="text-3xl ">{content.imageDescription}</p>
                </section>

                <section className="flex flex-col sm:flex-row gap-4 mb-4">
                  <div className="flex-1 bg-[#18f7b1] text-black p-4 rounded-xl">
                    <FLRating rating={content.flRating || '0'} />
                  </div>
                  
                  <div className="flex-1 bg-black text-white p-4 rounded-xl">
                    <div className="mb-4">
                      <h4 className="text-xl font-bold mb-2">EMBARRASSMENT LEVEL:</h4>
                      <CustomRating rating={parseInt(content.embarrassmentLevel) || 0} />
                    </div>
                    <div className="mb-4">
                      <h4 className="text-xl font-bold mb-2">RISK OF ARREST/DIVORCE/FIRING:</h4>
                      <CustomRating rating={parseInt(content.riskLevel) || 0} />
                    </div>
                    <div>
                      <h4 className="text-xl font-bold mb-2">LONG TERM EFFECTS:</h4>
                      <CustomRating rating={parseInt(content.longTermEffects) || 0} />
                    </div>
                  </div>
                </section>

                <section className="border-black border-4 rounded-xl p-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-4">
                      <div>
                        <h3 className="text-lg sm:text-xl font-bold mb-2 flex"><img src={require("../assets/FL_Logo_Loser_Icon_Blk.png")} className="w-[5%]" /> EQUIPMENT LOCKER:</h3>
                        <p className="text-sm sm:text-base">{content.equipmentLocker || 'N/A'}</p>
                      </div>
                      <div>
                        <h3 className="text-lg sm:text-xl font-bold mb-2 flex"> <img src={require("../assets/FL_Logo_Loser_Icon_Blk.png")} className="w-[5%]" />EQUIPMENT NEEDED:</h3>
                        <ul className="list-disc list-inside text-sm sm:text-base">
                          {(content.equipmentNeeded || []).map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="space-y-4">
                      <div className="flex items-start">
                        <Clock className="mr-2 flex-shrink-0 mt-1" />
                        <div>
                          <h3 className="text-lg sm:text-xl font-bold">TIME TO COMPLETE:</h3>
                          <p className="text-sm sm:text-base">{content.timeToComplete || 'N/A'}</p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <DollarSign className="mr-2 flex-shrink-0 mt-1" />
                        <div>
                          <h3 className="text-lg sm:text-xl font-bold">COST TO COMPLETE:</h3>
                          <p className="text-sm sm:text-base">{content.costToComplete || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="space-y-8">
                  <h3 className="text-3xl sm:text-4xl font-bold mb-4">Challenge Instructions:</h3>
                  {(content.instructions || []).map((instruction, index) => (
                    <div key={index} className="space-y-4">
                      <h4 className="text-2xl sm:text-3xl font-bold text-black">Step {index + 1}</h4>
                      <div className="border-l-4 border-[#18f7b1] pl-4">
                        <p className="mb-2 text-xl sm:text-2xl">{instruction.description}</p>
                        {instruction.image && renderImage(instruction.image, `Instruction ${index + 1}`)}
                      </div>
                    </div>
                  ))}
                </section>

                <section>
                  <h3 className="text-xl sm:text-2xl font-bold mb-4">Ways to Spice It Up:</h3>
                  <ul className="list-disc list-inside text-sm sm:text-base">
                    {(content.waysToSpiceUp || []).map((way, index) => (
                      <li key={index}>{way}</li>
                    ))}
                  </ul>
                </section>

                <section className="flex flex-wrap gap-2 mb-4">
                  {(content.tags || []).map((tag) => (
                    <span key={tag} className={`px-2 py-1 ${theme === 'dark' ? 'bg-[#444444]' : 'bg-gray-200'} text-xs sm:text-sm rounded`}>{tag}</span>
                  ))}
                </section>
              </div>
            )
          ) : (
            <div className="space-y-4">
              <div className="w-full h-48 sm:h-64 md:h-96 overflow-hidden rounded-lg">
              <video 
                  src={content.videoUrl} 
                  controls
                  className="w-full h-full object-contain"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
              <h2 className="text-2xl font-bold">{content.title}</h2>
              <p className="text-sm sm:text-base">{content.description}</p>
            </div>
          )}
        </div>

        {/* Carousel section with full width */}
        <div className="w-full bg-white mt-12 mb-10">
          <div className="container mx-auto px-4 max-w-8xl">
            <h2 className="text-5xl font-semibold mb-6 text-black text-left font-eau italic">MORE PUNISHMENTS</h2>
            <ContentCarousel 
              items={carouselItems} 
              onItemClick={handleCarouselItemClick} 
              cardsToShow={3}
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContentPage;