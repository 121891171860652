import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentItem, DraftLotteryVideo } from '../types';
import { useTheme } from '../contexts/ThemeContext';

interface ModalProps {
  item: ContentItem | DraftLotteryVideo | null;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ item, onClose }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  if (!item) return null;

  const isContentItem = (item: ContentItem | DraftLotteryVideo): item is ContentItem => {
    return 'type' in item;
  };

  const handleViewFullContent = () => {
    onClose(); // Close the modal first
    navigate(`/punishments/${item.id}`); // Then navigate to the content page
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className={`bg-${theme === 'dark' ? '[#333333]' : 'white'} rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto border border-[#18f7b1]`}>
        <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'}`}>{item.title}</h2>
        {isContentItem(item) ? (
          <>
            {item.image && (
              <div className="w-full mb-4 rounded-lg overflow-hidden" style={{ maxHeight: '50vh' }}>
                <img 
                  src={typeof item.image === 'string' ? item.image : URL.createObjectURL(item.image)}
                  alt={item.title} 
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            )}
            <p className={`${theme === 'dark' ? 'text-white' : 'text-[#333333]'} mb-4`}>{item.imageDescription}</p>
            <p className={`text-sm ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'} mb-2`}>Type: {item.type}</p>
            <div className="flex flex-wrap gap-2 mb-4">
              {item.tags.map((tag) => (
                <span key={tag} className={`px-2 py-1 ${theme === 'dark' ? 'bg-[#444444] text-white' : 'bg-gray-200 text-[#333333]'} text-sm rounded`}>{tag}</span>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="w-full mb-4 rounded-lg overflow-hidden" style={{ maxHeight: '50vh' }}>
              <video 
                src={item.videoUrl} 
                controls 
                className="w-full h-full object-contain rounded-lg"
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <p className={`${theme === 'dark' ? 'text-white' : 'text-[#333333]'} mb-4`}>{item.description}</p>
          </>
        )}
        <div className="flex justify-between">
          <button 
            onClick={handleViewFullContent}
            className={`px-4 py-2 ${theme === 'dark' ? 'bg-[#18f7b1] text-[#333333]' : 'bg-[#0fd494] text-white'} rounded hover:opacity-80 transition duration-300`}
          >
            View Full Content
          </button>
          <button 
            onClick={onClose}
            className={`px-4 py-2 ${theme === 'dark' ? 'bg-[#444444] text-white' : 'bg-gray-200 text-[#333333]'} rounded hover:opacity-80 transition duration-300`}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;