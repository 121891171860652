import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useYahooAuth } from '../contexts/YahooAuthContext';
import axios from 'axios';

const YahooCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateYahooAuthState } = useYahooAuth();

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      const state = searchParams.get('state');

      console.log('Starting Yahoo callback process');

      if (code && state) {
        try {
          console.log('Received code from Yahoo:', code);
          
          console.log('Sending request to token exchange endpoint');
          const response = await axios.post('/.netlify/functions/yahoo-token', { code });

          console.log('Token response received:', response.data);

          const { access_token, refresh_token, expires_in, token_type } = response.data;
          
          console.log('Updating Yahoo auth state');
          await updateYahooAuthState(access_token, refresh_token, expires_in, token_type);
          console.log('Yahoo auth state updated successfully');
          
          const redirectPath = localStorage.getItem('yahoo_auth_redirect') || '/dashboard';
          console.log('Redirecting to:', redirectPath);
          localStorage.removeItem('yahoo_auth_redirect');
          
          navigate(redirectPath);
        } catch (error) {
          console.error('Error during Yahoo authentication:', error);
          if (axios.isAxiosError(error)) {
            console.error('Response data:', error.response?.data);
            console.error('Response status:', error.response?.status);
          }
          navigate('/error');
        }
      } else {
        console.error('No code or state received from Yahoo');
        navigate('/error');
      }
    };

    handleCallback();
  }, [navigate, location, updateYahooAuthState]);

  return <div>Processing Yahoo authentication...</div>;
};

export default YahooCallback;