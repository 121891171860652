import React, { useState, useEffect, useRef } from 'react';
import { ContentItem, DraftLotteryVideo } from '../types';

interface ContentCarouselProps {
  items: (ContentItem | DraftLotteryVideo)[];
  onItemClick: (item: ContentItem | DraftLotteryVideo) => void;
  cardsToShow?: number;
}

const ContentCarousel: React.FC<ContentCarouselProps> = ({ 
  items, 
  onItemClick, 
  cardsToShow = 3
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    setCurrentIndex(0);
  }, [items]);

  const nextItems = () => {
    setCurrentIndex((prevIndex) => 
      Math.min(prevIndex + 1, items.length - (isMobile ? 1 : cardsToShow))
    );
  };

  const prevItems = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const renderItemContent = (item: ContentItem | DraftLotteryVideo) => {
    return (
      <div className="flex flex-col h-full justify-between">
        <h3 className="text-3xl font-semibold mb-4 text-[#18f7b1] overflow-hidden overflow-ellipsis"
            style={{ wordBreak: 'break-word', overflowWrap: 'break-word', hyphens: 'auto' }}>
          {item.title}
        </h3>
        <p className="text-xl text-gray-300 flex-grow overflow-y-auto"
           style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
          {'description' in item ? item.description : item.subtitle}
        </p>
      </div>
    );
  };

  if (items.length === 0) {
    return <div className="text-center text-gray-400">No items to display</div>;
  }

  const cardWidth = isMobile ? 'w-full' : `w-1/${cardsToShow}`;

  return (
    <div className="relative">
      <div 
        ref={carouselRef}
        className="overflow-hidden"
      >
        <div 
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * (100 / (isMobile ? 1 : cardsToShow))}%)` }}
        >
          {items.map((item, index) => (
            <div 
              key={item.id || `item-${index}`} 
              className={`flex-shrink-0 px-4 ${cardWidth}`}
            >
              <div 
                className="p-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300 h-[24rem] md:h-[28rem] border border-[#18f7b1] overflow-hidden"
                onClick={() => onItemClick(item)}
                style={{
                  backgroundImage: `url(${'image' in item ? item.image : '/placeholder-image.jpg'})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div className="bg-black bg-opacity-50 h-full rounded p-6">
                  {renderItemContent(item)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button 
        onClick={prevItems} 
        className="absolute top-1/2 -left-6 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 rounded-full"
        aria-label="Previous"
        disabled={currentIndex === 0}
      >
        <svg width="24" height="24" viewBox="0 0 40 40" className="rotate-180">
          <path d="M15 5 L30 20 L15 35" fill="#00ffc3" stroke="#00ffc3" strokeWidth="1" 
                filter="drop-shadow(2px 2px 1px rgba(0,0,0,0.3))" />
        </svg>
      </button>
      <button 
        onClick={nextItems} 
        className="absolute top-1/2 -right-6 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 rounded-full"
        aria-label="Next"
        disabled={currentIndex >= items.length - (isMobile ? 1 : cardsToShow)}
      >
        <svg width="24" height="24" viewBox="0 0 40 40">
          <path d="M15 5 L30 20 L15 35" fill="#00ffc3" stroke="#00ffc3" strokeWidth="1" 
                filter="drop-shadow(2px 2px 1px rgba(0,0,0,0.3))" />
        </svg>
      </button>
    </div>
  );
};

export default ContentCarousel;