import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { addWeeklyChallenge } from '../services/firebase';

interface WeeklyChallengeFormProps {
  userRole: string | null;
}

const WeeklyChallengeForm: React.FC<WeeklyChallengeFormProps> = ({ userRole }) => {
  const { theme } = useTheme();
  const [challengeName, setChallengeName] = useState('');
  const [challengeDescription, setChallengeDescription] = useState('');
  const [challengeWeek, setChallengeWeek] = useState('');
  const [challengePosition, setChallengePosition] = useState('');
  const [challengeError, setChallengeError] = useState<string | null>(null);
  const [challengeSuccess, setChallengeSuccess] = useState<string | null>(null);

  const nflPositions = ['QB', 'RB', 'WR', 'TE', 'K', 'DEF'];

  const handleCreateChallenge = async (e: React.FormEvent) => {
    e.preventDefault();
    setChallengeError(null);
    setChallengeSuccess(null);

    if (userRole !== 'admin') {
      setChallengeError('Only admins can create weekly challenges');
      return;
    }

    try {
      await addWeeklyChallenge({
        name: challengeName,
        description: challengeDescription,
        week: parseInt(challengeWeek),
        position: challengePosition,
      });
      setChallengeSuccess('Weekly challenge created successfully');
      setChallengeName('');
      setChallengeDescription('');
      setChallengeWeek('');
      setChallengePosition('');
    } catch (error) {
      setChallengeError('Failed to create weekly challenge');
      console.error('Error creating weekly challenge:', error);
    }
  };

  return (
    <form onSubmit={handleCreateChallenge} className={`${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} shadow-md rounded px-8 pt-6 pb-8 mb-8`}>
      <h2 className="text-2xl font-bold mb-4">Create Weekly Challenge</h2>
      {challengeError && (
        <div className="mb-4 text-red-500">{challengeError}</div>
      )}
      {challengeSuccess && (
        <div className="mb-4 text-green-500">{challengeSuccess}</div>
      )}
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="challengeName">
          Challenge Name
        </label>
        <input
          type="text"
          id="challengeName"
          value={challengeName}
          onChange={(e) => setChallengeName(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="challengeDescription">
          Challenge Description
        </label>
        <textarea
          id="challengeDescription"
          value={challengeDescription}
          onChange={(e) => setChallengeDescription(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="challengeWeek">
          Week
        </label>
        <input
          type="number"
          id="challengeWeek"
          value={challengeWeek}
          onChange={(e) => setChallengeWeek(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
          required
          min="1"
          max="17"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="challengePosition">
          Position
        </label>
        <select
          id="challengePosition"
          value={challengePosition}
          onChange={(e) => setChallengePosition(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
          required
        >
          <option value="">Select a position</option>
          {nflPositions.map((position) => (
            <option key={position} value={position}>{position}</option>
          ))}
        </select>
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Create Weekly Challenge
        </button>
      </div>
    </form>
  );
};

export default WeeklyChallengeForm;